import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Banner, BlogPosts, Footer, Header, Menu, SEO } from '../components';

export default function HomePage() {
  const blogPosts = useStaticQuery(graphql`
    query PostsQuery {
      allWpPost(limit: 10) {
        edges {
          node {
            author {
              node {
                name
                avatar {
                  url
                }
                databaseId
              }
            }
            categories {
              nodes {
                name
                slug
                databaseId
              }
            }
            date(formatString: "D [de] MMMM [de] YYYY", locale: "pt-br")
            excerpt
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 310, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                      src
                    }
                  }
                }
                title
              }
            }
            databaseId
            slug
            title
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO title="Home" />

      <Header />

      <Menu />

      <Banner />

      <BlogPosts posts={blogPosts.allWpPost.edges} />

      <Footer />
    </>
  );
}
